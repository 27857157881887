import {
  WorkspaceItemDto,
  OrganizationItemDto,
  CampaignItemDto,
  AssetItemDto,
  AssetReviewItemDto,
  AssetVersionItemDto,
  AssetPermissionsDto,
  TaskItemDto,
  WorkspaceMemberGroupItemDto
} from '@api/Api';

export type WorkspaceMemberGroupInviteModalStateProps = {
  key: string;
  visible: boolean;
  workspace: WorkspaceItemDto;
  group: WorkspaceMemberGroupItemDto;
};
export type WorkspaceMemberGroupCreateModalStateProps = {
  key: string;
  visible: boolean;
  workspace: WorkspaceItemDto;
  members?: { id: string; name: string; email: string }[];
};
export type WorkspaceMemberGroupModalStateProps = {
  key: string;
  visible: boolean;
  workspace: WorkspaceItemDto;
  group: WorkspaceMemberGroupItemDto;
};
export type WorkspaceModalStateProps = {
  visible: boolean;
  workspace?: WorkspaceItemDto;
  isEditing?: boolean;
};
export type WorkspaceOrganizationLeaveModalStateProps = {
  visible: boolean;
  entity?: WorkspaceItemDto | OrganizationItemDto;
  entityName: string;
  default?: string | null;
};
export type TaskModalStateProps = {
  visible: boolean;
  descriptionPlaceholder?: string;
  parentalTaskBoard?: { id: string; name: string };
  selectedTask?: any;
  selectedCampaign?: CampaignItemDto | null;
  selectedAsset?: {
    versionId: string;
    name: string;
    extension: string;
    id: string;
    folder?: {
      name: string | undefined;
      id: string;
    } | null;
  } | null;
  handlerCreateTask?(task: TaskItemDto): Promise<void> | void;
  handlerAfterClose?(task: any): void;
  handlerDelete?(id: string, permanent?: boolean): void;
  handlerRestore?(task: TaskItemDto): void;
  handlerRecovery?(task: TaskItemDto): void;
  taskInCampaign?: boolean;
};

export type ApprovalModalStateProps = {
  visible: boolean;
  isLastVersion: boolean;
  isEditMode?: boolean;
  assetVersion: AssetVersionItemDto;
  assetId: string;
  allowCreate: boolean;
  openFormCampaignPage?: boolean;
  callback?: (asset: AssetItemDto) => void;
};

export type BillingModalStateProps = {
  visible: boolean;
};

export type UploadAssetModalState = {
  key: string;
  visible: boolean;
  newVersionFromMediaViewer?: boolean;
  postAction?:
    | 'redirect-to-campaign'
    | 'redirect-to-campaign-tour'
    | 'redirect-to-mv'
    | 'redirect-to-aw';
  workspaceId: string;
};

export type NewAssetWithWorkflowState = {
  visible: boolean;
  isSubmittedWorkflow: boolean;
};

export type AgencyModalState = {
  visible: boolean;
  agency: any;
};

export type RenameEntityModalProps = {
  visible: boolean;
  entity: AssetItemDto | CampaignItemDto;
  page: 'folder' | 'media' | 'campaign';
  versionId?: string;
};

export type EntityDeleteModalProps = {
  permanent: boolean;
  visible: boolean;
  entity: AssetItemDto | CampaignItemDto;
  page: 'folder' | 'asset' | 'campaign';
  func?: () => void;
};
export type SingInExternalReviewerState = {
  visible: boolean;
};

export enum ModalNames {
  CAMPAIGN_INFO_MODAL = 'CAMPAIGN_INFO_MODAL',
  CAMPAIGN_DETAILS_MODAL = 'CAMPAIGN_DETAILS_MODAL',
  TAGGING_MODAL = 'TAGGING_MODAL',
  AGENCY_MODAL = 'AGENCY_MODAL'
}

export type GenericModalData = {
  modalName: ModalNames;
  payload?: object;
};

export type ShareAssetModalStateProps = {
  visible: boolean;
  assets: { asset: AssetItemDto; versionId?: string }[];
  action: 'create' | 'edit';
  review?: AssetReviewItemDto;
  updFunc?: ({
    id,
    allowComments,
    allowDownloads,
    allowAllVersions,
    password
  }: {
    id: string;
    allowComments: boolean;
    allowDownloads: boolean;
    allowAllVersions: boolean;
    password: string | null;
  }) => void;
};

export type CampaignInfoModalProps = {
  visible: boolean;
  campaign: CampaignItemDto | null;
  activeTab: 'details' | 'members' | 'shared_links' | 'settings';
};

export type CampaignFolderCreationModalProps = {
  visible: boolean;
  entity: 'campaign' | 'folder';
  createFromOnboarding?: boolean;
  onFinish?: ({
    name,
    isPrivate
  }: {
    name: string;
    isPrivate: boolean;
  }) => void;
};

export type OrganizationRenameModalProps = {
  visible: boolean;
  organization: OrganizationItemDto;
};

export type CampaignCreatedModalProps = {
  visible: boolean;
  campaignId: string;
};

export type CampaignInvitationModalProps = {
  visible: boolean;
  campaign: CampaignItemDto;
};

export type MoveAssetToPrivateFolderModalProps = {
  assetsName: string[];
  folderName: string;
  visible: boolean;
  callback: any;
};

export type ManageAssetVersionsModalProps = {
  versions: AssetVersionItemDto[];
  permissions: AssetPermissionsDto | null;
  visible: boolean;
  assetId: string;
  versionsCount: number;
};

export type ChangePlanDetailsModalProps = {
  visible: boolean;
  value: 'free' | 'starter' | 'team';
  paymentMethod: 'monthly' | 'annually';
};

export type CancelSubscriptionModalProps = {
  visible: boolean;
};

export type ReachedEntityLimitModalProps = {
  visible: boolean;
  entity:
    | 'campaign'
    | 'contributor'
    | 'task group'
    | 'task assignees'
    | 'workspace'
    | 'assets size'
    | 'user'
    | 'stages'
    | 'stage members'
    | 'admins'
    | 'unknown';
  hideUpgradeButton?: boolean;
};

export type AssetStackErrorModalProps = {
  visible: boolean;
  copy: string;
};

export type ManageFolderAccessModalProps = {
  key: string;
  visible: boolean;
  folder: AssetItemDto;
};

export type ChangeFolderAccessModalProps = {
  visible: boolean;
  folder: AssetItemDto;
  callback: (folder: AssetItemDto) => void;
};

export type CombineAssetsModalModalProps = {
  visible: boolean;
  assetId: string;
  toAssetId: string;
  toAssetName: string;
};

export interface CopyAssetVersionModalProps {
  visible: boolean;
  assetId: string;
  versionId: string;
  assetName: string;
  isAssetWithAW: boolean;
}

export interface IModalState {
  activeModal: GenericModalData | null;
  workspaceCreationModal: WorkspaceModalStateProps | null;
  workspaceInvitationModal: WorkspaceModalStateProps | null;
  workspaceMemberGroupInviteModal: WorkspaceMemberGroupInviteModalStateProps | null;
  workspaceMemberGroupCreateModal: WorkspaceMemberGroupCreateModalStateProps | null;
  workspaceMemberGroupModal: WorkspaceMemberGroupModalStateProps | null;
  workspaceOrganizationDeletionModal: WorkspaceOrganizationLeaveModalStateProps | null;
  workspaceMembersModal: WorkspaceModalStateProps | null;
  workspaceOrganizationLeaveModal: WorkspaceOrganizationLeaveModalStateProps | null;
  taskModal: TaskModalStateProps | null;
  approvalModal: ApprovalModalStateProps | null;
  billingModal: BillingModalStateProps | null;
  uploadAssetModal: UploadAssetModalState | null;
  shareAssetModal: ShareAssetModalStateProps | null;
  newAssetWithWorkflow: NewAssetWithWorkflowState | null;
  agencyModal: AgencyModalState | null;
  entityRenameModal: RenameEntityModalProps | null;
  entityDeletionModal: EntityDeleteModalProps | null;
  singInExternalReviewerModal: SingInExternalReviewerState | null;
  campaignInfoModal: CampaignInfoModalProps | null;
  campaignFolderCreationModal: CampaignFolderCreationModalProps | null;
  organizationRenameModal: OrganizationRenameModalProps | null;
  campaignCreatedModal: CampaignCreatedModalProps | null;
  campaignInvitationModal: CampaignInvitationModalProps | null;
  moveAssetToPrivateFolderModal: MoveAssetToPrivateFolderModalProps | null;
  manageAssetVersionsModal: ManageAssetVersionsModalProps | null;
  changePlanDetailsModal: ChangePlanDetailsModalProps | null;
  cancelSubscriptionModal: CancelSubscriptionModalProps | null;
  reachedEntityLimitModal: ReachedEntityLimitModalProps | null;
  assetStackErrorModal: AssetStackErrorModalProps | null;
  manageFolderAccessModal: ManageFolderAccessModalProps | null;
  changeFolderAccessModal: ChangeFolderAccessModalProps | null;
  combineAssetsModal: CombineAssetsModalModalProps | null;
  copyAssetVersionModal: CopyAssetVersionModalProps | null;
}

export interface IModalAction {
  type: string;
  payload?: any;
}

export enum ModalActionTypes {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  TOGGLE_WORKSPACE_CREATION_MODAL = 'TOGGLE_WORKSPACE_CREATION_MODAL',
  TOGGLE_WORKSPACE_INVITATION_MODAL = 'TOGGLE_WORKSPACE_INVITATION_MODAL',
  TOGGLE_WORKSPACE_MEMBER_GROUP_INVITE_MODAL = 'TOGGLE_WORKSPACE_MEMBER_GROUP_INVITE_MODAL',
  TOGGLE_WORKSPACE_MEMBER_GROUP_CREATE_MODAL = 'TOGGLE_WORKSPACE_MEMBER_GROUP_CREATE_MODAL',
  TOGGLE_WORKSPACE_MEMBER_GROUP_MODAL = 'TOGGLE_WORKSPACE_MEMBER_GROUP_MODAL',
  TOGGLE_WORKSPACE_ORGANIZATION_DELETION_MODAL = 'TOGGLE_WORKSPACE_ORGANIZATION_DELETION_MODAL',
  TOGGLE_WORKSPACE_MEMBERS_MODAL = 'TOGGLE_WORKSPACE_MEMBERS_MODAL',
  TOGGLE_BILLING_MODAL = 'TOGGLE_BILLING_MODAL',
  TOGGLE_NEW_ASSET_WITH_WORKFLOW_MODAL = 'TOGGLE_NEW_ASSET_WITH_WORKFLOW_MODAL',
  TOGGLE_WORKSPACE_ORGANIZATION_LEAVE_MODAL = 'TOGGLE_WORKSPACE_ORGANIZATION_LEAVE_MODAL',
  TOGGLE_ORGANIZATION_RENAME_MODAL = 'TOGGLE_ORGANIZATION_RENAME_MODAL',
  TOGGLE_TASK_MODAL = 'TOGGLE_TASK_MODAL',
  TOGGLE_APPROVAL_MODAL = 'TOGGLE_APPROVAL_MODAL',
  TOGGLE_UPLOAD_ASSET_MODAL = 'TOGGLE_UPLOAD_ASSET_MODAL',
  TOGGLE_SHARE_ASSET_MODAL = 'TOGGLE_SHARE_ASSET_MODAL',
  TOGGLE_RENAME_ENTITY_MODAL = 'TOGGLE_RENAME_ENTITY_MODAL',
  TOGGLE_DELETE_ASSET_MODAL = 'TOGGLE_DELETE_ASSET_MODAL',
  TOGGLE_SIGN_IN_EXTERNAL_REVIEWER_MODAL = 'TOGGLE_SIGN_IN_EXTERNAL_REVIEWER_MODAL',
  TOGGLE_CAMPAIGN_INFO_MODAL = 'TOGGLE_CAMPAIGN_INFO_MODAL',
  TOGGLE_CAMPAIGN_FOLDER_CREATION_MODAL = 'TOGGLE_CAMPAIGN_FOLDER_CREATION_MODAL',
  TOGGLE_CAMPAIGN_CREATED_MODAL = 'TOGGLE_CAMPAIGN_CREATED_MODAL',
  TOGGLE_INVITE_TO_CAMPAIGN_MODAL = 'TOGGLE_INVITE_TO_CAMPAIGN_MODAL',
  TOGGLE_MOVE_ASSET_TO_PRIVATE_FOLDER_MODAL = 'TOGGLE_MOVE_ASSET_TO_PRIVATE_FOLDER_MODAL',
  TOGGLE_MANAGE_ASSET_VERSIONS_MODAL = 'TOGGLE_MANAGE_ASSET_VERSIONS_MODAL',
  TOGGLE_CHANGE_PLAN_DETAILS_MODAL = 'TOGGLE_CHANGE_PLAN_DETAILS_MODAL',
  TOGGLE_CANCEL_SUBSCRIPTION_MODAL = 'TOGGLE_CANCEL_SUBSCRIPTION_MODAL',
  TOGGLE_REACHED_ENTITY_LIMIT__MODAL = 'TOGGLE_REACHED_ENTITY_LIMIT__MODAL',
  TOGGLE_ASSET_STACK_ERROR_MODAL = 'TOGGLE_ASSET_STACK_ERROR_MODAL',
  TOGGLE_MANAGE_FOLDER_ACCESS_MODAL = 'TOGGLE_MANAGE_FOLDER_ACCESS_MODAL',
  TOGGLE_CHANGE_FOLDER_ACCESS_MODAL = 'TOGGLE_FOLDER_ACCESS_MODAL',
  TOGGLE_COMBINE_ASSETS_MODAL = 'TOGGLE_COMBINE_ASSETS_MODAL',
  TOGGLE_COPY_ASSET_VERSION_MODAL = 'TOGGLE_COPY_ASSET_VERSION_MODAL'
}
